import React, { ReactNode } from 'react';

interface ButtonProps {
  variant?: 'default' | 'text';
  children?: ReactNode;
  type?: 'button' | 'reset' | 'submit';
  onClick?(): void;
  icon?: ReactNode;
  className?: string;
  helperClass?: string;
  textClass?: string;
  disabled?: boolean;
  style?: any;
}
function Button({
  variant = 'default',
  children,
  type = 'button',
  icon,
  helperClass,
  className,
  textClass,
  onClick,
  disabled,
  style
}: ButtonProps) {
  const styleHandler = () => {
    if (variant == 'text') {
      return `group flex items-center font-sans select-none`;
    }
    if (variant == 'default') {
      return `font-sans px-9 py-3 rounded flex items-center justify-center select-none`;
    }
  };
  return (
    <button
      type={type}
      disabled={disabled ? true : false}
      onClick={onClick}
      style={style}
      className={
        (disabled ? 'pointer-events-none cursor-default opacity-50 ' : ' ') +
        styleHandler() +
        ' ' +
        `${className ? className : ''}`
      }>
      {icon ? <span className={helperClass}>{icon}</span> : null}
      {children && (
        <span className={`${textClass ? textClass : ''}`}>{children}</span>
      )}
    </button>
  );
}

export default React.memo(Button);
