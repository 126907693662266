import { useRouter } from 'next/router';
import Button from '../components/Button/Button';
import { useEffect } from 'react';

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    const log404Error = async () => {
      
      // Check if the User-Agent contains "Googlebot"
      if (!navigator.userAgent.includes('Googlebot')) {
        return;
      }
      const date = new Date();
      const formattedDate = `[${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}:${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${date.getTimezoneOffset() / 60}]`;

      // Replace "-" with the actual data if you have it
      const logData = ` - - ${formattedDate} "GET ${window.location.protocol}//${window.location.hostname}${window.location.pathname} HTTP/1.1" 404 -`;
      const options = {
        method: 'POST',
        body: JSON.stringify({ logMessage: logData }),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await fetch('/api/logError?key=404', options);
    };

    log404Error();
  }, []);

  
  return (
    <div className="relative">
      <section>
        <div className="flex flex-col items-center justify-center h-[840px] z-10 mdx:h-[700px]">
          <h1 className="font-medium text-xl">
            404 This page could not be found.
          </h1>
          <Button
            className="text-center  bg-brand-black50 mt-10"
            textClass="font-medium text-white text-sm"
            onClick={() => {
              router.push('/');
            }}>
            Go HomePage
          </Button>
        </div>
      </section>
    </div>
  );
}

